<template>
  <div>
    <b-row>
      <b-col cols="8" class="d-flex align-items-center justify-content-start">
        <h4>Konfirmasi Penyelesaian</h4>
      </b-col>
    </b-row>
    <b-table-simple hover small caption-top responsive>
      <caption>Ini adalah uraian total halaman dari bab atau sub-bab lain yang ada:</caption>
      <colgroup><col><col></colgroup>
      <colgroup><col><col><col></colgroup>
      <colgroup><col><col></colgroup>
      <b-thead head-variant="secondary">
        <b-tr>
          <b-th>Nama</b-th>
          <b-th>Jumlah Halaman</b-th>
          <b-th>Jumlah Kata</b-th>
          <b-th>Batas Halaman</b-th>
          <b-th>Batas Kata</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="form in forms.filter(form => form.type !== 'finish')" :key="form.id" :variant="getRowVariant(form)">
          <b-td>{{ form.title }}</b-td>
          <b-td>{{ form.total_pages }}</b-td>
          <b-td>{{ form.total_words === 0 ? '-' : form.total_words }}</b-td>
          <b-td>{{ getFormPageLimits(form.template_id - 1) === 0 ? '-' : getFormPageLimits(form.template_id - 1) }}</b-td>
          <b-td>{{ getFormWordLimits(form.template_id - 1) === 0 ? '-' : getFormWordLimits(form.template_id - 1) }}</b-td>
        </b-tr>
      </b-tbody>
      <b-tfoot>
        <b-tr>
          <b-td><b>Total</b></b-td>
          <b-td><b>{{ forms.map(form => form.total_pages).filter(pages => pages != undefined).reduce((a, b) => a + b, 0) }}</b></b-td>
          <b-td><b>{{ forms.map(form => form.total_words).filter(words => words != undefined).reduce((a, b) => a + b, 0) }}</b></b-td>
        </b-tr>
      </b-tfoot>
    </b-table-simple>
    <b-alert v-if="isPass" show variant="success" class="p-1 mt-3">
      <b-row>
        <b-col cols="8" class="d-flex align-items-center justify-content-start">
          <p><i><strong>Sistem Telah Otomatis Mengkonfirmasi Penyelesaian</strong></i>, file bisa didownload dengan menekan tombol <strong>"Download File"</strong></p>
        </b-col>
        <b-col cols="4" class="d-flex align-items-center justify-content-end">
          <b-button
            variant="primary"
            class="btn-icon mt-1"
            :disabled="false"
            @click="handleDownload"
          >
            <feather-icon
              icon="DownloadCloudIcon"
              size="16"
            />
            Download File
          </b-button>
        </b-col>
      </b-row>
    </b-alert>
  </div>
</template>

<script>
import FORMS from './forms';

export default {
  props: {
    forms: {
      type: Array,
      default: () => ({})
    },
    downloadAble: {
      type: String,
      required: false,
      default: String,
    },
  },
  data() {
    return {
      filename: null,
      status: 'not-confirmed'
    }
  },
  computed: {
    isPass() {
      return true
    },
  },
  methods: {
    getRowVariant(form) {
      return ''
    },
    getFormWordLimits(template_id) {
      try {
        return template_id ? FORMS.find(form => form.id === template_id).word_limits : 0
      } catch (err) {
        console.log(`handling error count 2 : ${err}`)
        return ''
      }
    },
    getFormPageLimits(template_id) {
      try {
        return template_id ? FORMS.find(form => form.id === template_id).page_limits : 0
      } catch (err) {
        console.log(`handling error count 3 : ${err}`)
        return ''
      }
    },
    handleDownload() {
      console.log(`link : ${process.env.VUE_APP_BACKEND_URL}/${this.downloadAble}`);
      window.open(`${process.env.VUE_APP_BACKEND_URL}/${this.downloadAble}`, '_blank');
    },

  }
}
</script>

<style>

</style>