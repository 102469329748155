<template>
  <div>
    <h2>Kata Pengantar</h2>
    <b-skeleton-wrapper :loading="isLoading">
      <template #loading>
        <b-card>
          <b-skeleton width="85%"></b-skeleton>
          <b-skeleton width="55%"></b-skeleton>
          <b-skeleton width="70%"></b-skeleton>
        </b-card>
      </template>
      <span v-html="form.data"></span>
      <!-- <editor
        :data="form.data"
        @input="handleEditorChange"
        @wordCount="handleEditoWordCountChange"
      /> -->
    </b-skeleton-wrapper>
    <b-row class="mt-2" gutter>
      <b-col cols="12">
        <b-row gutter>
          <b-col cols="2">
            <h4>Jumlah Kata</h4>
          </b-col>
          <b-col cols="10">
            <h4><b>{{ total_words }}</b> Kata</h4>
          </b-col>
        </b-row>
      </b-col>

      <b-col cols="12" class="mt-1">
        <b-row gutter>
          <b-col cols="2">
            <h4>Jumlah Halaman</h4>
          </b-col>
          <b-col cols="10">
            <h4><b>{{ form.total_pages }}</b> Halaman</h4>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Editor from '@/views/components/Editor';

import useJwt from '@/auth/jwt/useJwt'
export default {
  components: {
    Editor
  },
  props: {
    form: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      isLoading: false,
      data: this.form.data,
      total_words: this.form.total_words
    };
  },
  methods: {
    async submit() {
      const data = {
        ...this.form,
        content: this.data,
        led_id: this.$route.params.id,
        total_words: this.total_words
      }
      const { data: resp } = await useJwt.http.put(`led_contents/${this.form.id}`, data);
      const form = {
        ...this.form,
        ...resp.data,
        total_words: this.total_words
      }
      this.$emit('submit', {...this.form, ...resp.data})
      return true;
    },
    handleEditorChange(val) {
      this.data = val;
    },
    // handleEditoWordCountChange(stats) {
    //   this.total_words = stats.words;
    // }
  }
}
</script>