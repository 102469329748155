<template>
  <b-card class="shadow" no-body>
    <b-row>
        <!-- BUTTONS -->
        <!-- <template v-if="isEdit">
          <b-button
            variant="primary"
            class="btn-icon mr-1"
            @click="handleEditConfirmItem"
          >
            <feather-icon
              icon="CheckIcon"
              size="16"
            />
          </b-button>
          <b-button 
            variant="danger"
            class="btn-icon mr-1"
            @click="handleEditCancelItem"
          >
            <feather-icon
              icon="DeleteIcon"
              size="16"
            />
          </b-button>
        </template>

        <template v-else>
          <b-button
            variant="warning"
            class="btn-icon mr-1"
            @click="handleEditItem"
          >
            <feather-icon
              icon="Edit3Icon"
              size="16"
            />
          </b-button>
        </template>
      </b-col> -->

      <!-- CONTENT -->
      <!-- <template v-if="isEdit">
        <b-col cols="8" class="d-flex align-items-center">
          <b-form-input
            v-model="mutableItem.name"
            placeholder="Masukkan judul..."
            autofocus
          />
        </b-col>
        <b-col cols="2" class="d-flex align-items-center justify-content-end">
          <b-form-input
            v-model="mutableItem.page"
            placeholder="Masukkan halaman..."
            autofocus
          />
        </b-col>
      </template> -->

      <b-col cols="9" class="d-flex align-items-center">
        <h3 class="m-0"><b>{{item.name}}</b></h3>
      </b-col>
      <b-col cols="2" class="d-flex align-items-center justify-content-end">
        <span class="mr-1">Halaman : {{item.page}}</span>
        <b-button
          v-if="$slots.default"
          variant="secondary"
          class="btn-icon"
          size="sm"
          @click="isCollapseOpen = !isCollapseOpen"
        >
          <feather-icon
            icon="ArrowDownCircleIcon"
            size="16"
          />
        </b-button>
      </b-col>
    </b-row>
    <template v-if="$slots.default" class="mt-2">
      <b-collapse v-model="isCollapseOpen" class="mt-2">
        <b-card no-body class="ml-3 mb-0">
          <slot></slot>
        </b-card>
      </b-collapse>
    </template>
    <template v-else class="mb-2"></template>
  </b-card>
</template>

<script>
import { confirm } from '@libs/utils'
export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    withoutAdd: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      isCollapseOpen: false,
      isEdit: false,
      mutableItem: {}
    }
  },
  methods: {
    handleDeleteItem() {
      confirm('Item daftar isi dan turunannya akan dihapus!')
        .then(() => this.$emit('delete'))
    },
    handleEditItem() {
      const { name, page } = this.item;
      this.mutableItem = { name, page };
      this.isEdit = true;
    },
    handleEditConfirmItem() {
      const { name, page } = this.mutableItem;
      const data = 'childs' in this.item ? { name, page, childs: this.item.childs } : { name, page };
      this.$emit('edit', this.item.id, data);
      this.isEdit = false;
    },
    handleEditCancelItem() {
      this.mutableItem = {};
      this.isEdit = false;
    },
    handleAddItem() {
      this.isCollapseOpen = true;
      this.$emit('add');
    }
  }
}
</script>

<style>

</style>