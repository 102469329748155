<template>
  <b-form>
    <b-row>
      <b-col md="6">
        <b-form-group>
          <label for="perguruan_tinggi">Perguruan Tinggi</label>
          <b-form-input id="perguruan_tinggi" />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group>
          <label for="unit_pengelola">Unit Pengelola Program Studi</label>
          <b-form-input id="unit_pengelola" />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group>
          <label for="jenis_program">Jenis Program</label>
          <b-form-input id="jenis_program" />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group>
          <label for="nama_program_studi">Nama Program Studi</label>
          <b-form-input id="nama_program_studi" />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group>
          <label for="alamat">Alamat</label>
          <b-form-input id="alamat" />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group>
          <label for="nomor_telepon">Nomor Telepon</label>
          <b-form-input id="nomor_telepon" />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group>
          <label for="email_website">E-Mail dan Website</label>
          <b-form-input id="email_website" />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group>
          <label for="nomor_sk_pendirian_pt">Nomor SK Pendirian PT</label>
          <b-form-input id="nomor_sk_pendirian_pt" />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group>
          <label for="tanggal_sk_pendirian_pt">Tanggal SK Pendirian PT</label>
          <b-form-input id="tanggal_sk_pendirian_pt" />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group>
          <label for="pejabat_penandatangan">Pejabat Penandatangan SK Pendirian PT</label>
          <b-form-input id="pejabat_penandatangan_sk_pendirian_pt" />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group>
          <label for="nomor_sk_pembukaan_ps">Nomor SK Pembukaan PS</label>
          <b-form-input id="nomor_sk_pembukaan_ps" />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group>
          <label for="tanggal_sk_pembukaan_ps">Tanggal SK Pembukaan PS</label>
          <b-form-input id="tanggal_sk_pembukaan_ps" />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group>
          <label for="tanggal_sk_pembukaan_ps">Tanggal SK Pembukaan PS</label>
          <b-form-input id="tanggal_sk_pembukaan_ps" />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group>
          <label for="pejabat_pembukaan_sk_pendirian_pt">Pejabat Pembukaan SK Pendirian PT</label>
          <b-form-input id="pejabat_pembukaan_sk_pendirian_pt" />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group>
          <label for="tahun_beasiswa">Tahun Pertama Kali Menerima Mahasiswa</label>
          <b-form-input id="tahun_beasiswa" />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group>
          <label for="peringkat_terbaru">Peringkat Terbaru Akreditasi PS</label>
          <b-form-input id="peringkat_terbaru" />
        </b-form-group> 
      </b-col>
      <b-col md="6">
        <b-form-group>
          <label for="nomor_sk_ban_pt">Nomor SK BAN-PT</label>
          <b-form-input id="nomor_sk_ban_pt" />
        </b-form-group>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
  },
  data() {
    return {
      title: 'Identitas Pengusul',
      finished: true
    }
  },
}
</script>